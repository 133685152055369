import React, { useContext, useMemo } from 'react'
import { Row, Col, Tag, Card } from 'antd'
import { formatCurrency } from './CostOptimizationUtil'
import { asset_manager_theme } from '../../../../assets/themes'
import { AssetPlanSelection, DateBoundsContext } from '../../PlanModelingTab'
import { noop, orderBy, sumBy } from 'lodash'
import { Asset, Recommendation } from '../../PlanModelingInterface'
import { styled } from 'styled-components'
import { PlanGraph } from '../../PlanGraph'
import { maxCostPerYear } from './CostOptimizationUtil'

const StyledCard = styled(Card)<{ onMouseEnter?: () => void }>`
  margin-top: 0px;
  margin-bottom: 16px;
  border-color: ${asset_manager_theme.background.background_color3};
  background-color: ${asset_manager_theme.background.background_color6};
  &:hover {
    background-color: ${({ onMouseEnter }) =>
      onMouseEnter
        ? asset_manager_theme.background.background_color8
        : asset_manager_theme.background.background_color6};
    cursor: ${({ onMouseEnter }) => (onMouseEnter ? 'pointer' : 'default')};
  }
`

export enum PlanDetailsViewMode {
  OLD = 'old',
  NEW = 'new',
  DIFFERENCE = 'difference',
}

interface PlanDetailsCardProps {
  oldPlanSelection: AssetPlanSelection
  planDetailsViewMode: PlanDetailsViewMode
  assets: Asset[]
  isCurrentSelectedCombination?: boolean
  isBelowBudget?: boolean
  isMinTCO?: boolean
  isRecommended?: boolean
  isCollapsed: boolean
  index?: number
  annualBudget?: number
  newPlanSelection?: AssetPlanSelection
  onClick?: () => void
  onHover?: () => void
  onHoverExit?: () => void
}

const calculateFinalTCO = (plans: AssetPlanSelection, assets: Asset[]): number => {
  return sumBy(Object.values(plans), (plan) => {
    const assetPlan = assets
      .flatMap((asset) => asset.plans)
      .find((p) => p?.plan_slug === plan.plan_slug)
    if (!assetPlan) {
      return 0
    }
    return sumBy(assetPlan.plan_recs ?? [], (rec) => rec.repair_cost ?? 0)
  })
}

export const PlanDetailsCard: React.FC<PlanDetailsCardProps> = ({
  planDetailsViewMode,
  isCurrentSelectedCombination,
  isBelowBudget,
  isMinTCO,
  isRecommended,
  isCollapsed,
  annualBudget,
  onClick,
  onHover,
  onHoverExit,
  oldPlanSelection,
  newPlanSelection,
  assets,
}) => {
  const oldPlanTCO = calculateFinalTCO(oldPlanSelection, assets)

  const newPlanTCO = newPlanSelection
    ? calculateFinalTCO(newPlanSelection, assets)
    : undefined

  const oldPlanMaxCost = maxCostPerYear(Object.values(oldPlanSelection), assets)
  const newPlanMaxCost = newPlanSelection
    ? maxCostPerYear(Object.values(newPlanSelection), assets)
    : undefined
  const yAxisMax = Math.max(oldPlanMaxCost, newPlanMaxCost ? newPlanMaxCost : 0)

  const { startDate, endDate } = useContext(DateBoundsContext)
  const yearsBetween = endDate.diff(startDate, 'year')

  const getPlanDetails = (planSelection: {
    [key: string]: { plan_slug?: string; action_taken?: string }
  }) => {
    return orderBy(
      Object.values(planSelection).map((plan) => {
        const assetPlan = assets
          .flatMap((asset) => asset.plans)
          .find((p) => p?.plan_slug === plan.plan_slug)
        return {
          ...assetPlan,
          total_plan_cost: assetPlan?.plan_recs?.reduce(
            (sum, rec) => sum + (rec.repair_cost ?? 0),
            0
          ),
        }
      }),
      (c) => c.total_plan_cost,
      'desc'
    )
  }

  const oldPlanDetails = getPlanDetails(oldPlanSelection)
  const newPlanDetails = newPlanSelection ? getPlanDetails(newPlanSelection) : undefined

  const geckoCount = sumBy(newPlanDetails ?? oldPlanDetails, (p) =>
    p.gecko_verified ? 1 : 0
  )

  const getPlanRecKey = (rec: Recommendation) =>
    `${rec.scheduled_date}-${rec.recommendation}`

  const combinedPlanDetails = oldPlanDetails
    .map((oldPlan) => {
      const newPlan = newPlanDetails
        ? newPlanDetails.find((np) => np.slug === oldPlan.slug)
        : undefined
      const oldPlanRecs =
        oldPlan.plan_recs?.reduce((acc, rec) => {
          acc[getPlanRecKey(rec)] = rec
          return acc
        }, {} as { [key: string]: Recommendation }) || {}

      const newPlanRecs = newPlan
        ? newPlan?.plan_recs?.reduce((acc, rec) => {
            acc[getPlanRecKey(rec)] = rec
            return acc
          }, {} as { [key: string]: Recommendation }) || {}
        : undefined

      const allKeys = newPlanRecs
        ? Array.from(new Set([...Object.keys(oldPlanRecs), ...Object.keys(newPlanRecs)]))
        : Array.from(new Set([...Object.keys(oldPlanRecs)]))

      const sortedRecs = newPlanRecs
        ? orderBy(
            allKeys.map((key) => ({
              oldRec: oldPlanRecs[key],
              newRec: newPlanRecs[key],
            })),
            [(rec) => rec.oldRec?.scheduled_date || rec.newRec?.scheduled_date],
            ['asc']
          )
        : orderBy(
            allKeys.map((key) => ({
              oldRec: oldPlanRecs[key],
              newRec: undefined,
            })),
            [(rec) => rec.oldRec?.scheduled_date],
            ['asc']
          )

      return {
        asset_name: oldPlan.asset_name,
        total_plan_cost: oldPlan.total_plan_cost,
        new_total_plan_cost: newPlan?.total_plan_cost,
        plan_recs: sortedRecs,
      }
    })
    .filter((plan) => plan.asset_name !== undefined)

  const isNewUndefined = !newPlanSelection || Object.keys(newPlanSelection).length === 0

  //TO-DO: Render jank, memo not working
  const renderGraph = useMemo(() => {
    const selectedPlanData = () => {
      if (
        newPlanSelection === undefined ||
        planDetailsViewMode === PlanDetailsViewMode.OLD
      ) {
        return oldPlanSelection
      } else if (
        planDetailsViewMode === PlanDetailsViewMode.DIFFERENCE ||
        planDetailsViewMode === PlanDetailsViewMode.NEW
      ) {
        return newPlanSelection
      } else {
        return oldPlanSelection
      }
    }

    return (
      <PlanGraph
        selectedPlans={selectedPlanData()}
        processedPlanData={assets}
        yAxisStuck={false}
        yAxisMax={yAxisMax}
        budgetValue={annualBudget ? { budgetLine: annualBudget } : undefined}
        setYAxisMax={noop}
      />
    )
  }, [
    annualBudget,
    assets,
    newPlanSelection,
    oldPlanSelection,
    planDetailsViewMode,
    yAxisMax,
  ])

  return (
    <StyledCard onClick={onClick} onMouseEnter={onHover} onMouseLeave={onHoverExit}>
      <Row>
        <Col span={12}>
          <h2>
            {newPlanTCO === undefined || planDetailsViewMode === PlanDetailsViewMode.OLD
              ? formatCurrency(oldPlanTCO)
              : formatCurrency(newPlanTCO)}
          </h2>
          {/* TODO Make risk-based tie back to whether plan is gecko verified*/}
          <p color={asset_manager_theme.background.background_color2}>
            {yearsBetween} yr • {assets.length} asset{assets.length > 0 ? 's' : ''} •{' '}
            {geckoCount}/{assets.length}
            Gecko'd
          </p>
        </Col>
        <Col
          span={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}>
          <Row style={{ gap: '0.5rem' }}>
            {isCurrentSelectedCombination && (
              <Tag color='yellow' style={{ margin: '0px', height: '22px' }}>
                Current Selection
              </Tag>
            )}
            {isBelowBudget && Boolean(annualBudget) && (
              <Tag color='cyan' style={{ margin: 0, height: '22px' }}>
                Below Budget
              </Tag>
            )}
            {isMinTCO && (
              <Tag color='green' style={{ margin: 0, height: '22px' }}>
                Lowest TCO
              </Tag>
            )}
            {isRecommended && (
              <Tag
                color='blue'
                style={{
                  margin: 0,
                  height: '22px',
                }}>
                Recommended
              </Tag>
            )}
          </Row>
        </Col>
      </Row>
      {isCollapsed === false && (
        <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>{renderGraph}</Row>
      )}

      {isCollapsed == false &&
        combinedPlanDetails.map((plan, index) => (
          <div key={index}>
            <Row>
              <Col span={16}>
                <p>
                  <strong>{plan.asset_name}</strong>
                </p>
              </Col>
              <Col span={8} style={{ textAlign: 'right' }}>
                {isNewUndefined || plan.total_plan_cost === plan.new_total_plan_cost ? (
                  <p>{formatCurrency(plan.total_plan_cost || 0)}</p>
                ) : (
                  <p>
                    <span style={{ color: 'red', textDecoration: 'line-through' }}>
                      {formatCurrency(plan.total_plan_cost || 0)}
                    </span>{' '}
                    <span style={{ color: 'green' }}>
                      {formatCurrency(plan.new_total_plan_cost || 0)}
                    </span>
                  </p>
                )}
              </Col>
            </Row>
            {plan.plan_recs.map((rec, idx) => {
              const isOnlyInOld = rec.oldRec && !rec.newRec
              const isOnlyInNew = !rec.oldRec && rec.newRec

              const color = isNewUndefined
                ? asset_manager_theme.background.background_color3
                : isOnlyInOld
                ? 'red'
                : isOnlyInNew
                ? 'green'
                : asset_manager_theme.background.background_color3

              const textDecoration = isNewUndefined
                ? 'none'
                : isOnlyInOld
                ? 'line-through'
                : 'none'

              return (
                <Row key={idx} style={{ marginBottom: '10px' }}>
                  <Col span={4}>
                    <p style={{ color, textDecoration }}>
                      {rec.oldRec?.scheduled_date || rec.newRec?.scheduled_date || '-'}
                    </p>
                  </Col>
                  <Col span={16}>
                    <p style={{ color, textDecoration }}>
                      {rec.oldRec?.recommendation || rec.newRec?.recommendation || '-'}
                    </p>
                  </Col>
                  <Col span={4} style={{ textAlign: 'right' }}>
                    <p style={{ color, textDecoration }}>
                      {rec.oldRec?.repair_cost
                        ? formatCurrency(rec.oldRec.repair_cost)
                        : rec.newRec?.repair_cost
                        ? formatCurrency(rec.newRec.repair_cost)
                        : '-'}
                    </p>
                  </Col>
                </Row>
              )
            })}
          </div>
        ))}
    </StyledCard>
  )
}
