import React from 'react'
import { Col } from 'antd'
import { partition } from 'lodash'
import { Asset, Plan } from '../../PlanModelingInterface'
import { AssetPlanSelection } from '../../PlanModelingTab'
import { calculateTCO, maxCostPerYear } from './CostOptimizationUtil'
import { PlanDetailsCard, PlanDetailsViewMode } from './PlanCard'

export const OptionsReview: React.FC<{
  sortedCombinations: Plan[][]
  assets: Asset[]
  oldSelectedPlans: AssetPlanSelection
  hoveredPlans?: AssetPlanSelection
  selectedPlans?: AssetPlanSelection
  onSelect: (plans: Plan[]) => void
  onHover: (plans: Plan[], enter: boolean) => void
  annualBudget?: number
  minTCO: number
}> = ({
  sortedCombinations,
  assets,
  oldSelectedPlans,
  hoveredPlans,
  onSelect,
  annualBudget,
  onHover,
  minTCO,
}) => {
  const [belowBudget, aboveBudget] = partition(sortedCombinations, (combo) =>
    annualBudget ? maxCostPerYear(combo, assets) < annualBudget : true
  )

  const isCurrentSelected = (combination: Plan[]): boolean => {
    const selectedPlanSlugs = Object.values(oldSelectedPlans).map(
      (plan) => plan.plan_slug
    )
    const combinationPlanSlugs = combination.map((plan) => plan.plan_slug)
    return combinationPlanSlugs.every((slug) => selectedPlanSlugs.includes(slug ?? ''))
  }

  const isHoveredSelection = (
    hoveredSelectedPlans: AssetPlanSelection | undefined,
    hovered: Plan[],
    processedPlanData: Asset[]
  ): boolean => {
    if (hoveredSelectedPlans === undefined) {
      return false
    }
    const expectedSelectedPlans: AssetPlanSelection = {}

    hovered.forEach((plan) => {
      const asset = processedPlanData.find((asset) => asset.plans?.includes(plan))
      if (asset) {
        expectedSelectedPlans[asset.id] = {
          plan_slug: plan.plan_slug,
          action_taken: plan.action_taken,
        }
      }
    })

    return JSON.stringify(hoveredSelectedPlans) === JSON.stringify(expectedSelectedPlans)
  }

  const renderOption = (combination: Plan[], index: number, belowBudget: boolean) => {
    if (combination.length === 0) {
      return null
    }
    const tco = calculateTCO(combination)

    const isCurrentSelectedCombination = isCurrentSelected(combination)

    const combinationAsAssetPlanSelection: AssetPlanSelection = combination.reduce(
      (acc, plan) => {
        if (plan.plan_slug) {
          acc[plan.plan_slug] = plan
        }
        return acc
      },
      {} as AssetPlanSelection
    )

    const formatPlanSelection = (planSelection: AssetPlanSelection, assets: Asset[]) => {
      return Object.keys(planSelection).reduce((acc, planSlug) => {
        const asset = assets.find((a) => a.plans?.some((p) => p.plan_slug === planSlug))
        if (asset) {
          const plan = asset.plans?.find((p) => p.plan_slug === planSlug)
          if (plan) {
            acc[asset.id] = {
              action_taken: planSelection[planSlug].action_taken,
              plan_slug: plan.plan_slug,
            }
          }
        }
        return acc
      }, {} as Record<string, { action_taken?: string; plan_slug?: string }>)
    }

    const combinationsFormatted = formatPlanSelection(
      combinationAsAssetPlanSelection,
      assets
    )

    return (
      <>
        <PlanDetailsCard
          key={index}
          planDetailsViewMode={PlanDetailsViewMode.DIFFERENCE}
          oldPlanSelection={oldSelectedPlans}
          newPlanSelection={combinationsFormatted}
          assets={assets}
          isCollapsed={!isHoveredSelection(hoveredPlans, combination, assets)}
          onHover={() => onHover(combination, true)}
          onHoverExit={() => onHover(combination, false)}
          onClick={() => onSelect(combination)}
          isCurrentSelectedCombination={isCurrentSelectedCombination}
          isBelowBudget={belowBudget}
          isMinTCO={tco === minTCO}
          index={index}
          annualBudget={annualBudget}
        />
      </>
    )
  }

  return (
    <Col
      style={{
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
        width: '100%',
        paddingRight: '2rem',
      }}>
      {belowBudget.map((combo, idx) => renderOption(combo, idx, true))}
      {aboveBudget.length > 0 && (
        <Col>
          <h2 style={{ fontStyle: 'italic', marginBottom: '1rem' }}>
            Plans Exceeding Annual Budget Requirements
          </h2>
          {aboveBudget.map((combo, idx) =>
            renderOption(combo, idx + belowBudget.length, false)
          )}
        </Col>
      )}
    </Col>
  )
}
