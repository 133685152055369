import React, { useState } from 'react'
import { Col } from 'antd'
import { Asset, Plan } from '../../PlanModelingInterface'
import { AssetPlanSelection } from '../../PlanModelingTab'
import { calculateTCO, maxCostPerYear } from './CostOptimizationUtil'
import { PlanDetailsCard, PlanDetailsViewMode } from './PlanCard'

export const PlanComparison: React.FC<{
  sortedCombinations: Plan[][]
  assets: Asset[]
  oldPlanSelection?: AssetPlanSelection
  newPlanSelection?: AssetPlanSelection
  annualBudget?: number
  minTCO: number
}> = ({
  sortedCombinations,
  assets,
  oldPlanSelection,
  newPlanSelection,
  annualBudget,
  minTCO,
}) => {
  const newPlanCombination = sortedCombinations
    .filter((combo) => {
      const planSlugs = new Set(combo.map((plan) => plan.plan_slug))
      const allMatch =
        newPlanSelection &&
        Object.values(newPlanSelection).every((selection) =>
          planSlugs.has(selection.plan_slug)
        )
      if (allMatch) {
        return true
      }
      return false
    })
    .flat()

  const isBelowBudget = annualBudget
    ? maxCostPerYear(newPlanCombination, assets) < annualBudget
    : undefined

  const isMinTCO = calculateTCO(newPlanCombination) === minTCO

  return (
    <Col
      style={{
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
        width: '100%',
        paddingRight: '2rem',
      }}>
      <h2 style={{ marginBottom: '0.5rem' }}>New CAPEX Plan Selected</h2>
      {oldPlanSelection && newPlanSelection && (
        <PlanDetailsCard
          isCollapsed={false}
          isCurrentSelectedCombination={false}
          isBelowBudget={isBelowBudget}
          isMinTCO={isMinTCO}
          oldPlanSelection={oldPlanSelection}
          newPlanSelection={newPlanSelection}
          assets={assets}
          planDetailsViewMode={PlanDetailsViewMode.NEW}
          annualBudget={annualBudget}
        />
      )}
    </Col>
  )
}
