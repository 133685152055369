import React, { FC, useContext, useState, useMemo, useEffect, createContext } from 'react'
import { PageContainer, Row, Column } from '../../components/Common/Grid'
import { Asset, Recommendation } from './PlanModelingInterface'
import { PlanGraph } from './PlanGraph'
import {
  submitSelectedPlans,
  useGetPlanData,
  useGetTriageData,
} from '../../api/useFDEBackend'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import {
  EmptyStateBanner,
  MainHeading,
  SubHeading,
} from '../../components/Common/EmptyStateBanner'
import { TabHeader } from '../../components/Common/TabHeader'
import { Button, DatePicker, Form, Modal, Spin } from 'antd'
import NumberLabelComponent from '../RecommendationsTab/RecommendationsKPIBar'
import PlanModelingTable, { AssetFieldSet } from './PlanModelingTable'
const RangePicker = DatePicker.RangePicker
import dayjs from 'dayjs'
import { debounce, filter, pickBy } from 'lodash'
import { FilterValue } from 'antd/es/table/interface'
import { dateSortHelper } from '../../api/tableHelpers'
import { FDECustomerUnitData } from '../AssetDatabaseTab/AssetDatabaseTabInterface'
import { NO_INFO_SET } from '../../util/constants'
import { useSearchParams } from 'react-router-dom'
import { FeatureFlagContext } from '../../contextProviders/featureFlagContextProvider'
import { TabKey } from '../AppLayout'
import { GLOBAL_SEARCH_PARAMS } from '../LoggedInContent'
import { createPlanModelingKPIData } from '../../components/helpers/PlanModelingHelpers'
import HighVisButton from '../../components/Common/HighVisButton'
import BudgetForm, { BudgetState } from './Components/PlanBudgetForm'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { asset_manager_theme } from '../../assets/themes'
import { CostMinimizerDrawer } from './Components/cost-minimizer/CostMinimizerDrawer'

export type AssetPlanSelection = Record<
  string,
  {
    plan_slug?: string
    action_taken?: string
  }
>

const defaultStartDate = dayjs()
const defaultEndDate = dayjs().add(10, 'year')

export const DateBoundsContext = createContext({
  startDate: defaultStartDate,
  endDate: defaultEndDate,
})

interface PlanModelingTabProps {
  setBaseballCardFdeCustomerUnitSlug: (slug: string | undefined) => void
}

export const PlanModelingTab: FC<PlanModelingTabProps> = (props) => {
  const authInfo = useAuthInfo()
  const siteData = useContext(SiteContext)
  const [_, __, planModelingDataSWR] = useGetPlanData(authInfo, siteData?.siteSlug)
  const [triageData, triageDataSWR] = useGetTriageData(authInfo, siteData?.siteSlug ?? '')

  const [isTCOMinimizerActive, setIsTCOMinimizerActive] = useState(false)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)

  const [selectedAssets, setSelectedAssets] = useState<Record<string, boolean>>({})
  const [isAssetsPlanSubmissionModalVisible, setIsAssetsPlanSubmissionModalVisible] =
    useState(false)

  const [selectedPlans, setSelectedPlans] = useState<AssetPlanSelection>({})
  const userSelectedPlans = pickBy(selectedPlans, (_, key) =>
    Boolean(selectedAssets[key])
  )

  const [planRecFilters, setPlanRecFilters] = React.useState<
    Record<string, FilterValue | null>
  >({})
  const [assetFilters, setAssetFilters] = React.useState<
    Record<string, FilterValue | null>
  >({})

  // Handle filters
  const [searchParams, setSearchParams] = useSearchParams()
  const [URLFiltersInitialized, setURLFiltersInitialized] = useState(false)

  useEffect(() => {
    const keys = Array.from(searchParams.keys())
    if (!keys.every((key) => GLOBAL_SEARCH_PARAMS.has(key))) {
      const assetFilters: Record<string, FilterValue | null> = {}
      const planRecFilters: Record<string, FilterValue | null> = {}

      searchParams.forEach((value, key) => {
        let parsedValue
        try {
          parsedValue = JSON.parse(value)
        } catch (e) {
          parsedValue = value
        }

        if (AssetFieldSet.has(key)) {
          assetFilters[key] = parsedValue
        } else {
          planRecFilters[key] = parsedValue
        }
      })

      setAssetFilters(assetFilters)
      setPlanRecFilters(planRecFilters)
      setURLFiltersInitialized(true)
    }
  }, [searchParams])

  useEffect(() => {
    if (URLFiltersInitialized) {
      // Only run this effect if initialization from URL has occurred
      const newSearchParams = new URLSearchParams()

      Object.entries({ ...assetFilters, ...planRecFilters }).forEach(([key, value]) => {
        if (value) {
          newSearchParams.set(key, JSON.stringify(value))
        }
      })

      setSearchParams(newSearchParams, { replace: true })
    }
  }, [assetFilters, planRecFilters, setSearchParams, URLFiltersInitialized])

  // Key feature flags
  const userFeatureFlags = useContext(FeatureFlagContext)
  const lockOutEnabled = userFeatureFlags?.[TabKey.planModeling]?.read_only ?? false
  const lockYAxisEnabled = useMemo(() => {
    return (
      (userFeatureFlags?.[TabKey.planModeling]?.lockYAxis ||
        userFeatureFlags?.superUser) ??
      false
    )
  }, [userFeatureFlags])

  const [planModelingData, setPlanModelingData] = useState(
    planModelingDataSWR.data?.['plan_modeling']
  )

  useEffect(() => {
    if (planModelingDataSWR.data?.['plan_modeling']) {
      const initialSelectedPlans: AssetPlanSelection = {}
      planModelingDataSWR.data?.['plan_modeling']?.forEach((asset) => {
        initialSelectedPlans[asset.id] = {
          plan_slug: asset.plans?.[0]?.plan_slug,
          action_taken: asset.plans?.[0]?.action_taken,
        }
      })
      setSelectedPlans(initialSelectedPlans)
      setPlanModelingData(planModelingDataSWR.data?.['plan_modeling'])
    }
  }, [planModelingDataSWR.data])

  const plansInPeriodPlanData: Asset[] | undefined = useMemo(() => {
    return structuredClone(planModelingData)
      ?.map((asset) => {
        const currentPlanSlug = selectedPlans[asset.id]?.plan_slug
        const selectedPlan = asset.plans?.find(
          (plan) => plan.plan_slug === currentPlanSlug
        )

        if (selectedPlan) {
          const filteredPlanRecs = selectedPlan.plan_recs?.filter((rec) => {
            const date = rec?.scheduled_date ? dayjs(rec.scheduled_date) : null
            return (
              // If there is no date, always include it
              !date ||
              ((date.isSame(startDate, 'year') || date.isAfter(startDate, 'year')) &&
                (date.isBefore(endDate, 'year') || date.isSame(endDate, 'year')))
            )
          })

          if (filteredPlanRecs && filteredPlanRecs.length > 0) {
            selectedPlan.plan_recs = filteredPlanRecs
            return { ...asset, selectedPlan }
          }
        }
        // Do not include invalid entries
        return undefined
      })
      .filter(Boolean) as Asset[]
  }, [planModelingData, selectedPlans, startDate, endDate])

  //Filtering and sorting data for both the table and the graph
  const processedPlanData: Asset[] = useMemo(() => {
    const assetNameSearchFilter = assetFilters['asset_name']
      ? (assetFilters['asset_name'][0] as string).toLowerCase()
      : ''

    const filteredModelingData = (
      structuredClone(plansInPeriodPlanData)?.filter((asset) => {
        //filter by asset name
        const matchesFilter =
          assetNameSearchFilter === '' ||
          asset?.asset_name?.toLowerCase().includes(assetNameSearchFilter)
        return matchesFilter
      }) || []
    ).filter((asset) => {
      //filter by other asset filters
      for (const [key, value] of Object.entries(assetFilters)) {
        if (key === 'asset_name') {
          continue
        }
        if (
          value &&
          value !== null &&
          value?.includes('---') &&
          NO_INFO_SET.has(
            asset?.fde_customer_unit_data?.[key as keyof FDECustomerUnitData] as string
          )
        ) {
          return true
        }
        if (
          value &&
          value !== null &&
          value?.includes(
            asset?.fde_customer_unit_data?.[key as keyof FDECustomerUnitData] as string
          ) === false
        ) {
          return false
        }
      }
      return true
    })

    return filteredModelingData?.reduce<any[]>((acc, curr, _ind) => {
      const currentPlan = curr.selectedPlan ?? {
        ...(curr?.plans?.find(
          (plan) => plan.plan_slug === selectedPlans[curr?.id ?? '']?.plan_slug
        ) ?? {}),
      }

      currentPlan.plan_recs = currentPlan?.plan_recs
        ?.filter((d) => {
          for (const [key, value] of Object.entries(planRecFilters)) {
            // This is a special case for the group filter, since we renamed cluster to group and also made it an array,
            // we need to treat it differently
            if (key === 'group') {
              if (
                value &&
                value !== null &&
                value?.includes('---') &&
                NO_INFO_SET.has(d?.cluster)
              ) {
                return true
              }
              if (
                value &&
                value !== null &&
                !d?.cluster?.some((clusterItem) => value.includes(clusterItem))
              ) {
                return false
              }
            } else {
              if (
                value &&
                value !== null &&
                value?.includes('---') &&
                NO_INFO_SET.has(d?.[key as keyof Recommendation])
              ) {
                return true
              }
              if (
                value &&
                value !== null &&
                value?.includes(d?.[key as keyof Recommendation] as string) === false
              ) {
                return false
              }
            }
          }
          return true
        })
        ?.sort((a, b) => {
          return dateSortHelper(dayjs(a?.scheduled_date), dayjs(b?.scheduled_date))
        })

      curr.selectedPlan = currentPlan
      acc.push(curr)

      return acc
    }, [])
  }, [plansInPeriodPlanData, selectedPlans, planRecFilters, assetFilters])

  const planModelingKPIData = useMemo(() => {
    return createPlanModelingKPIData(
      processedPlanData,
      plansInPeriodPlanData,
      assetFilters,
      planRecFilters
    )
  }, [processedPlanData, plansInPeriodPlanData, assetFilters, planRecFilters])

  // Manage y axis scaling on chart
  const [yAxisStuck, setYAxisStuck] = useState(true)
  const [yAxisMax, setYAxisMax] = useState(0.0)

  const [budgetLine, setBudgetLine] = React.useState<BudgetState>({
    budgetLine: -1,
  })

  const handleBudgetUpdate = (newBudget: number) => {
    if (newBudget) {
      setBudgetLine((prevState) => ({
        ...prevState,
        budgetLine: newBudget,
      }))
    } else {
      setBudgetLine((prevState) => ({
        ...prevState,
        budgetLine: -1,
      }))
    }
  }

  //Manage budget modal visibility
  const [isBudgetModalVisible, setIsBudgetModalVisible] = useState(false)
  const [budgetModalForm] = Form.useForm()

  // Function to submit the selected plans of the selected assets
  const submitSelectedAssetsPlans = (selectedPlans: AssetPlanSelection) => {
    submitSelectedPlans(authInfo, selectedPlans, siteData?.siteSlug).then(() => {
      planModelingDataSWR?.mutate()
      triageDataSWR.mutate()
      setSelectedAssets({})
      setIsAssetsPlanSubmissionModalVisible(false) // Close the modal after submitting
    })
  }
  const debouncedSubmitSelectedAssetsPlans = debounce(submitSelectedAssetsPlans, 300)
  const selectedAssetsCount = Object.values(selectedAssets).filter(Boolean).length

  if (
    !planModelingDataSWR.isLoading &&
    (!planModelingDataSWR.data?.['plan_modeling']?.length ||
      planModelingDataSWR.data?.['plan_modeling'].length < 1)
  ) {
    return (
      <EmptyStateBanner>
        <div style={{ paddingBottom: '9rem' }}>
          <MainHeading>No plans approved for modeling</MainHeading>
          <SubHeading>Manage plans in the Recommendations tab</SubHeading>
        </div>
      </EmptyStateBanner>
    )
  }

  return (
    <PageContainer style={{ maxWidth: '100%' }}>
      <DateBoundsContext.Provider value={{ startDate, endDate }}>
        <TabHeader style={{ maxHeight: 'none', justifyContent: 'space-between' }}>
          <Column>Plan Modeling</Column>
          <Column>
            <Row style={{ justifyContent: 'flex-end', gap: '1rem' }}>
              <RangePicker
                picker={'year'}
                defaultValue={[defaultStartDate, defaultEndDate]}
                allowClear={false}
                onChange={(values) => {
                  setStartDate(values?.[0] || defaultStartDate)
                  setEndDate(values?.[1] || defaultEndDate)
                }}
              />
            </Row>
          </Column>
        </TabHeader>
        <Spin spinning={planModelingDataSWR.isLoading}>
          <Column style={{ marginBottom: '1rem' }}>
            <NumberLabelComponent input={planModelingKPIData} title='In Plan' />
            <Button
              style={{
                width: '100%',
                height: 'max-content',
                marginTop: '1rem',
                color: 'rgba(255,255,255,0.9',
                padding: '0.5rem',
                borderColor: 'rgba(255, 255, 0, 0.5)',
                backgroundColor: 'rgba(255, 255, 0, 0.2)',
              }}
              onClick={() => setIsTCOMinimizerActive(true)}>
              <h3>Optimize Capital Allocation</h3>
            </Button>
          </Column>
        </Spin>
        <Row style={{ flexDirection: 'row-reverse' }}>
          <div style={{ marginTop: '1rem' }}></div>
        </Row>
        <Spin spinning={planModelingDataSWR.isLoading}>
          <Row
            style={{
              justifyContent: 'center',
              minHeight: '300px',
              maxHeight: '40vh',
              position: 'relative',
            }}>
            <PlanGraph
              processedPlanData={processedPlanData}
              planModelingDataSWR={planModelingDataSWR}
              selectedPlans={selectedPlans}
              yAxisStuck={yAxisStuck}
              yAxisMax={yAxisMax}
              budgetValue={budgetLine}
              setYAxisMax={setYAxisMax}
            />
          </Row>
        </Spin>
        <Row style={{ justifyContent: 'right', marginTop: '1rem' }}>
          <Modal
            title={budgetLine.budgetLine !== -1 ? 'Edit Budget' : 'Add Budget'}
            open={isBudgetModalVisible} // CheckLockout
            onCancel={() => {
              setIsBudgetModalVisible(false)
              budgetModalForm.resetFields()
            }}
            footer={null}
            destroyOnClose={true}>
            <div
              style={{
                maxHeight: '70vh',
                overflowY: 'scroll',
                margin: '2rem 0rem 2rem 0rem',
              }}>
              <BudgetForm
                form={budgetModalForm}
                handleSave={handleBudgetUpdate}
                setIsModalVisible={setIsBudgetModalVisible}
                budgetState={budgetLine}
              />
            </div>
            <HighVisButton
              onClick={() => {
                budgetModalForm.submit()
              }}>
              Save
            </HighVisButton>
            &nbsp; &nbsp;
          </Modal>
          {lockYAxisEnabled && (
            <Button
              onClick={() => setYAxisStuck(!yAxisStuck)}
              style={{
                marginLeft: '1rem',
                color: asset_manager_theme.colors.grey,
                borderColor: asset_manager_theme.colors.grey_light,
              }}>
              {yAxisStuck ? <LockOutlined /> : <UnlockOutlined />}Y Axis
            </Button>
          )}
          {/* {budgetPluginEnabled && (
          <Button
            onClick={() => setIsBudgetModalVisible(true)}
            style={{ marginLeft: '1rem' }}>
            <EditOutlined />
            Budget
          </Button>
        )} */}
          <Button
            onClick={() => setIsAssetsPlanSubmissionModalVisible(true)}
            // CheckLockout
            disabled={lockOutEnabled || selectedAssetsCount === 0}
            style={{
              marginRight: '1rem',
              marginLeft: '1rem',
              borderColor: asset_manager_theme.colors.grey_light,
            }}>
            {' '}
            Save Plan{selectedAssetsCount !== 1 ? 's' : ''} for{' '}
            {selectedAssetsCount > 0 ? `${selectedAssetsCount} ` : ''}Asset
            {selectedAssetsCount !== 1 ? 's' : ''}
          </Button>
          <Modal
            title='Confirm Submission'
            open={isAssetsPlanSubmissionModalVisible}
            onOk={() =>
              // Only submit plans that have been manually checked by the user
              debouncedSubmitSelectedAssetsPlans(userSelectedPlans)
            }
            onCancel={() => setIsAssetsPlanSubmissionModalVisible(false)}>
            <p>Are you sure you want to submit plans for the selected assets?</p>
          </Modal>
        </Row>
        <Spin spinning={planModelingDataSWR.isLoading}>
          <Row
            style={{
              justifyContent: 'center',
              maxHeight: '47%',
            }}>
            <PlanModelingTable
              selectedPlans={selectedPlans}
              planModelingDataSWR={planModelingDataSWR}
              setBaseballCardFdeCustomerUnitSlug={
                props.setBaseballCardFdeCustomerUnitSlug
              }
              setSelectedPlans={setSelectedPlans}
              triageDataSwr={triageDataSWR}
              selectedAssets={selectedAssets}
              processedPlanData={processedPlanData}
              setPlanRecFilters={setPlanRecFilters}
              setAssetFilters={setAssetFilters}
              setSelectedAssets={setSelectedAssets}
            />
          </Row>
        </Spin>
        <CostMinimizerDrawer
          isPanelActive={isTCOMinimizerActive}
          setPanelActive={setIsTCOMinimizerActive}
          selectedPlans={selectedPlans}
          setSelectedPlans={setSelectedPlans}
          processedPlanData={processedPlanData}
        />
      </DateBoundsContext.Provider>
    </PageContainer>
  )
}
