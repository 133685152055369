import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import dayjs from 'dayjs'
import { Asset } from './PlanModelingInterface'
import { CategoryScale } from 'chart.js'
import { asset_manager_theme } from '../../assets/themes'
import { SWRResponse } from 'swr'
import { Dispatch, SetStateAction, useContext, useEffect, useRef } from 'react'
import { BudgetState } from './Components/PlanBudgetForm'
import { AssetPlanSelection } from './PlanModelingTab'
import { DateBoundsContext } from './PlanModelingTab'

interface PlanGraphProps {
  planModelingDataSWR?: SWRResponse<
    {
      plan_modeling: Asset[]
      plan_summary: Asset[]
    },
    any,
    any
  >
  selectedPlans?: AssetPlanSelection
  processedPlanData: Asset[]
  yAxisStuck: boolean
  yAxisMax: number
  budgetValue?: BudgetState
  setYAxisMax: Dispatch<SetStateAction<number>>
}

export const PlanGraph: React.FC<PlanGraphProps> = (props) => {
  const {
    selectedPlans,
    processedPlanData,
    yAxisStuck,
    yAxisMax,
    budgetValue,
    setYAxisMax,
  } = props

  const { startDate, endDate } = useContext(DateBoundsContext)

  const startYear = startDate.year()
  const endYear = endDate.year()
  ChartJS.register(CategoryScale)

  // Organize plan data
  type CategorizedExpenseTimeline = Record<string, Record<number, Record<string, number>>>
  const expenseTimeline: CategorizedExpenseTimeline = {}

  for (const assetId in selectedPlans) {
    const planSlugForAsset = selectedPlans[assetId].plan_slug
    const matchingAsset = processedPlanData?.find((asset) => asset.id === assetId)

    if (matchingAsset && planSlugForAsset) {
      const matchingPlan = matchingAsset.plans?.find(
        (p) => p.plan_slug == planSlugForAsset
      )

      if (matchingPlan && matchingPlan.plan_recs) {
        for (const rec of matchingPlan.plan_recs) {
          const { recommended_action, scheduled_date, repair_cost, is_tentative } = rec
          // If the rec has all the values to show up in the chart
          if (recommended_action && scheduled_date && repair_cost) {
            const year = dayjs(scheduled_date).get('year')
            // If budget category doesn't exist as a key and the other fields are also valid, instantiate then add the year onto it
            expenseTimeline[recommended_action] ??= {}
            expenseTimeline[recommended_action][year] ??= {
              tentative: 0,
              planned: 0,
            }

            // Add recommendation's cost to the respective year and category
            const yearRecord =
              expenseTimeline[recommended_action][dayjs(scheduled_date).get('year')]
            is_tentative
              ? (yearRecord['tentative'] += repair_cost)
              : (yearRecord['planned'] += repair_cost)
          }
        }
      }
    }
  }

  const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i)

  //Horizontal budget line
  const budgetLinePlugin = {
    id: 'budgetLinePlugin',
    afterDraw: (
      chart: {
        ctx: any
        chartArea: {
          top: number
          right: number
          bottom: number
          left: number
          width: number
          height: number
        }
        scales: { x: CategoryScale; y: CategoryScale }
      },
      args: unknown,
      options: { lineThickness: number; lineColor: string; budgetValue: number }
    ) => {
      const {
        ctx,
        chartArea: { left, width },
        scales: { y },
      } = chart
      if (options.budgetValue !== -1) {
        ctx.save()
        const yThickness = options.lineThickness
        ctx.strokeStyle = options.lineColor
        const yCoord = y.getPixelForValue(options.budgetValue) - yThickness / 2
        ctx.setLineDash([5, 5]) // Set the dash pattern
        ctx.lineWidth = yThickness
        ctx.beginPath()
        ctx.moveTo(left, yCoord)
        ctx.lineTo(left + width, yCoord)
        ctx.stroke()
        ctx.setLineDash([]) // Reset the dash pattern
        ctx.restore()
      }
    },
    lineColor: 'yellow',
    budgetValue: 0.0,
    lineThickness: 0.0,
  }

  ChartJS.register(budgetLinePlugin)

  const options: any = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 10,
        },
      },
      ...(budgetValue
        ? {
            budgetLinePlugin: {
              lineColor: 'yellow',
              lineThickness: 4,
              budgetValue: budgetValue.budgetLine,
            },
          }
        : {}),
    },
    animation: {
      duration: 500,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: yAxisStuck
        ? {
            stacked: true,
            grid: {
              display: false,
            },
          }
        : {
            stacked: true,
            grid: {
              display: false,
            },
            min: 0.0,
            max: yAxisMax,
          },
    },
  }

  const chartRef = useRef<any>(null)

  useEffect(() => {
    const chart = chartRef.current
    if (chart) {
      setYAxisMax(chart.scales.y.max)
    }
  }, [selectedPlans, processedPlanData, yAxisStuck, setYAxisMax])

  return (
    <Bar
      ref={chartRef}
      style={{
        width: '100%',
        height: '5%',
      }}
      data={{
        labels: years,
        datasets: [
          {
            label: `Capital Planned`,
            data: years.map((year) => expenseTimeline.CAP?.[year]?.planned || 0),
            backgroundColor: asset_manager_theme.colors.yellow,
          },
          {
            label: `Capital Tentative`,
            data: years.map((year) => expenseTimeline.CAP?.[year]?.tentative || 0),
            backgroundColor: asset_manager_theme.colors.yellow_light,
          },
          {
            label: `Maintenance Planned`,
            data: years.map((year) => expenseTimeline.Maintenance?.[year]?.planned || 0),
            backgroundColor: asset_manager_theme.colors.grey,
          },
          {
            label: `Maintenance Tentative`,
            data: years.map(
              (year) => expenseTimeline.Maintenance?.[year]?.tentative || 0
            ),
            backgroundColor: asset_manager_theme.colors.grey_light,
          },
          {
            label: `Inspections Planned`,
            data: years.map((year) => expenseTimeline.Inspection?.[year]?.planned || 0),
            backgroundColor: asset_manager_theme.colors.blue,
          },
          {
            label: `Inspections Tentative`,
            data: years.map((year) => expenseTimeline.Inspection?.[year]?.tentative || 0),
            backgroundColor: asset_manager_theme.colors.blue_light,
          },
        ],
      }}
      options={options}
    />
  )
}
