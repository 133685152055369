import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as Tabs from '@radix-ui/react-tabs'
import { asset_manager_theme } from '../../../assets/themes'
import StyledInput from '../../../components/Common/StyledInput'
import { EditableStringElement } from '../../../components/Common/EditableStringElement'

interface TabProps {
  // Deliberately not camelcase - this makes it through to the DOM
  // in TabExtended and triggers React warnings if not in all-lower
  activeplan: number
  index: number
}

const TabExtended = styled(Tabs.Trigger)<TabProps>`
  border: none;
  background: none;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 5rem;
  color: ${({ activeplan, index }) => {
    if (activeplan === index - 1) {
      return asset_manager_theme.background.high_vis
    }
    return 'white'
  }};
  ${({ activeplan, index }) => {
    if (activeplan === index - 1) {
      return ` border-bottom: solid 2px ${asset_manager_theme.background.high_vis};`
    }
  }}

  padding-bottom: 0.5rem;
`

const PlanTab: React.FC<{
  plan_name?: string
  // plan_slug used for unique key
  plan_slug?: string
  refetch: () => void
  activePlan: number
  index: number
  resetTabs: boolean
  nullifyEdits: () => void
  setActivePlan: React.Dispatch<React.SetStateAction<number>>
  isApprovedPlan: boolean
}> = ({
  plan_name,
  activePlan,
  plan_slug,
  index,
  resetTabs,
  nullifyEdits,
  setActivePlan,
  refetch,
  isApprovedPlan,
}) => {
  const [placeHolderName, setPlaceHolderName] = useState<string | undefined>(plan_name)
  const [doubleClicked, setDoubleClicked] = useState<boolean>(false)

  useEffect(() => {
    setPlaceHolderName(plan_name)
  }, [plan_name])

  useEffect(() => {
    setDoubleClicked(false)
  }, [resetTabs])

  // Adds a checkmark
  return (
    <TabExtended
      activeplan={activePlan}
      index={index}
      value={plan_name || ''}
      key={plan_slug}
      onDoubleClick={() => {
        if (activePlan === index - 1) {
          setDoubleClicked(true)
        }
      }}
      onClick={() => {
        if (activePlan !== index - 1) {
          nullifyEdits()
          setActivePlan(index - 1)
        }
      }}>
      {doubleClicked ? (
        <EditableStringElement
          style={{ width: '100%' }}
          key={plan_slug}
          value={placeHolderName}
          objectId={plan_slug || ''}
          field='plan_name'
          refetchData={() => {
            refetch()
            setDoubleClicked(false)
          }}
          debounceTime={700}
          rolodexType='fde-am-plan'
        />
      ) : (
        placeHolderName
      )}
      {isApprovedPlan && `\u00a0\u00a0\u2714`}
    </TabExtended>
  )
}
export default PlanTab
