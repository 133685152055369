import React, { useEffect, useMemo, useState } from 'react'
import { Row } from '../../../../components/Common/Grid'
import { CloseButton } from '../../../../components/Common/CloseButton'
import SlidePanel from '../../../../components/Common/SlidePanel'
import { Button, Checkbox, Col, Divider, InputNumber, Select } from 'antd'
import { asset_manager_theme } from '../../../../assets/themes'
import { OptionsReview } from './OptionsReview'
import { PlanComparison } from './PlanComparison'
import { AssetPlanSelection } from '../../PlanModelingTab'
import { Asset, Plan } from '../../PlanModelingInterface'
import { calculateTCO, formatCurrency } from './CostOptimizationUtil'
import { orderBy, sortBy } from 'lodash'
import { PlanDetailsCard, PlanDetailsViewMode } from './PlanCard'

export interface CostMinimizerDrawerProps {
  isPanelActive: boolean
  setPanelActive: (active: boolean) => void
  selectedPlans: AssetPlanSelection
  setSelectedPlans: (plans: AssetPlanSelection) => void
  processedPlanData: Asset[]
}

export const CostMinimizerDrawer: React.FC<CostMinimizerDrawerProps> = ({
  isPanelActive,
  selectedPlans,
  processedPlanData,
  setPanelActive,
  setSelectedPlans,
}) => {
  const [newPlanSelection, setNewPlanSelection] = useState<AssetPlanSelection>()
  const [hoveredPlanSelection, setHoveredPlanSelection] = useState<AssetPlanSelection>()
  const [maxAnnualBudget, setMaxAnnualBudget] = useState<number>()
  const [fullGeckoOnly, setFullGeckoOnly] = useState<boolean>()
  const [minTCO, setMinTCO] = useState(Infinity)

  const handleBack = () => setNewPlanSelection(undefined)

  const handleSaveAndApply = () => {
    if (newPlanSelection) {
      setSelectedPlans(newPlanSelection)
      setPanelActive(false)
      setNewPlanSelection(undefined)
    }
  }

  const selectOption = (selected: Plan[]) => {
    const newSelectedPlans: AssetPlanSelection = {}

    selected.forEach((plan) => {
      const asset = processedPlanData.find((asset) => asset.plans?.includes(plan))
      if (asset) {
        newSelectedPlans[asset.id] = {
          plan_slug: plan.plan_slug,
          action_taken: plan.action_taken,
        }
      }
    })

    setNewPlanSelection(newSelectedPlans)
  }

  const hoverOption = (hovered: Plan[], enter: boolean) => {
    const newSelectedPlans: AssetPlanSelection = {}
    if (enter) {
      hovered.forEach((plan) => {
        const asset = processedPlanData.find((asset) => asset.plans?.includes(plan))
        if (asset) {
          newSelectedPlans[asset.id] = {
            plan_slug: plan.plan_slug,
            action_taken: plan.action_taken,
          }
        }
      })
    }
    setHoveredPlanSelection(newSelectedPlans)
  }

  // Sort by assets with steepest dropoff between most and second-most expensive plans
  const assetsByCostDropoff = useMemo(
    () =>
      orderBy(
        processedPlanData,
        (a) => {
          const sortedPlans = orderBy(
            a.plans?.filter(
              (p) => Boolean(p.total_plan_cost) && (!fullGeckoOnly || p.gecko_verified)
            ) ?? [],
            (p) => p.total_plan_cost
          )
          return (
            (sortedPlans[0]?.total_plan_cost ?? 0) -
            (sortedPlans[1]?.total_plan_cost ?? 0)
          )
        },
        'asc'
      ),

    [processedPlanData, fullGeckoOnly]
  )

  const combinations: Plan[][] = useMemo(() => {
    // Allow expansion of the 4 flattest-dropoff assets (up to 2 cheapest plans)
    // This allows for choice while reducing cartesian complexity
    return assetsByCostDropoff.reduce((planArray, asset, idx) => {
      const validPlans = asset.plans?.filter((p) => !fullGeckoOnly || p.gecko_verified)
      if (validPlans?.length) {
        const cheapestPlans = orderBy(
          validPlans,
          (p) => p.total_plan_cost ?? Infinity,
          'asc'
        ).slice(0, idx < 4 ? 2 : 1)
        return cheapestPlans.flatMap((plan) =>
          planArray.length === 0 ? [[plan]] : planArray.map((e) => [plan, ...e])
        )
      }
      return planArray
    }, [] as Plan[][])
  }, [assetsByCostDropoff, fullGeckoOnly])
  const sortedCombinations = sortBy(combinations, (c) => calculateTCO(c))

  useEffect(() => {
    setMinTCO(sortedCombinations[0] ? calculateTCO(sortedCombinations[0]) : Infinity)
  }, [sortedCombinations])

  return (
    <SlidePanel
      active={isPanelActive}
      panelStyle={{
        display: isPanelActive ? undefined : 'none',
        overflow: 'hidden',
      }}>
      <Row>
        <CloseButton
          style={{
            height: '3rem',
          }}
          onClick={() => setPanelActive(false)}
        />
        <h1 style={{ color: asset_manager_theme.text.c1 }}>
          Optimize Capital Allocation
        </h1>
      </Row>
      <Divider
        style={{
          borderColor: asset_manager_theme.background.background_color4,
          margin: '1rem 0',
        }}
      />
      <Row style={{ color: asset_manager_theme.text.c1, gap: '1rem' }}>
        <Col span={12} style={{ minWidth: '50%' }}>
          <h2 style={{ marginBottom: '0.5rem' }}>Current CAPEX Plan</h2>
          <Row>
            <PlanDetailsCard
              isCollapsed={false}
              isCurrentSelectedCombination={true}
              isBelowBudget={false}
              isMinTCO={false}
              oldPlanSelection={selectedPlans}
              newPlanSelection={undefined}
              assets={processedPlanData}
              annualBudget={maxAnnualBudget}
              planDetailsViewMode={PlanDetailsViewMode.OLD}
            />
          </Row>
        </Col>
        {!newPlanSelection ? (
          <Col
            span={12}
            style={{
              gap: '2rem',
            }}>
            <h2>Alternative CAPEX Plans</h2>
            <Row
              style={{
                gap: '1rem',
                display: 'flex',
                alignItems: 'center',
                margin: '1rem 0',
              }}>
              <p>Filters</p>
              <Select
                value={
                  maxAnnualBudget
                    ? formatCurrency(maxAnnualBudget) + ' Annual Budget'
                    : 'Max Annual Budget'
                }
                dropdownRender={() => (
                  <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
                    <InputNumber
                      style={{ flex: 'auto', marginRight: '8px' }}
                      value={maxAnnualBudget}
                      formatter={(value) => (value ? formatCurrency(value) : '')}
                      onChange={(val) =>
                        setMaxAnnualBudget(val ? parseInt(val.toString()) : undefined)
                      }
                    />
                  </div>
                )}
              />
              <p>Fully Gecko-Derived Only</p>
              <Checkbox
                title='Gecko-Derived Only'
                checked={fullGeckoOnly}
                onChange={() => setFullGeckoOnly((curr) => !curr)}
              />
            </Row>
            <OptionsReview
              sortedCombinations={sortedCombinations}
              minTCO={minTCO}
              assets={processedPlanData}
              oldSelectedPlans={selectedPlans}
              hoveredPlans={hoveredPlanSelection}
              onSelect={selectOption}
              annualBudget={maxAnnualBudget}
              onHover={hoverOption}
            />
          </Col>
        ) : (
          <Col>
            <PlanComparison
              oldPlanSelection={selectedPlans}
              newPlanSelection={newPlanSelection}
              assets={processedPlanData}
              sortedCombinations={sortedCombinations}
              annualBudget={maxAnnualBudget}
              minTCO={minTCO}></PlanComparison>
          </Col>
        )}
      </Row>

      {Boolean(newPlanSelection) && (
        <>
          <Button
            type='primary'
            onClick={handleBack}
            style={{
              position: 'absolute',
              bottom: '100px',
              right: '220px',
            }}>
            Back
          </Button>
          <Button
            type='primary'
            onClick={handleSaveAndApply}
            style={{
              position: 'absolute',
              bottom: '100px',
              right: '80px',
            }}>
            Save and Apply
          </Button>
        </>
      )}
    </SlidePanel>
  )
}
